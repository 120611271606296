import { gql } from "@apollo/client";

export const SIGNUP_USER = gql`
  mutation SignUp($signUpInput: SignUpInput!) {
    signUp(signUpInput: $signUpInput)
  }
`;

export const FETCH_GOOGLE_URL = gql`
  mutation SignInWithGoogleUrl($redirectUri: String!) {
    signInWithGoogleUrl(redirectUri: $redirectUri)
  }
`;

export const VERIFY_USER_EMAIL = gql`
  mutation VerifyAccount($verifyInput: VerifyEmailInput!) {
    verifyAccount(verifyInput: $verifyInput) {
      accessToken
      email
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
      refreshToken
    }
  }
`;

export const RESEND_ACCOUNT_VERIFY_CODE = gql`
  mutation ResendAccountVerifyCode($email: String!) {
    resendAccountVerifyCode(email: $email)
  }
`;

export const LOGIN_WITH_GOOGLE_URL = gql`
  mutation SignInWithGoogle($code: String!, $redirectUri: String) {
    signInWithGoogle(code: $code, redirectUri: $redirectUri) {
      email
      accessToken
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
    }
  }
`;

export const UPDATE_USERDATA = gql`
  mutation UpdateUserData($updateDataInput: UpdateDataInput!, $file: Upload) {
    updateUserData(updateDataInput: $updateDataInput, file: $file) {
      about_us
      coupon
      email
      first_name
      id
      image_url
      is_referred
      is_verified
      last_name
      phone_number
      signup_date
    }
  }
`;

export const USER_CONFIG = gql`
  query Config($partner: String!) {
    config {
      plans(partner: $partner) {
        active
        amount
        code
        currency
        id
        interval
        partner
      }
    }
  }
`;

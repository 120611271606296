import { useMutation } from "@apollo/client";
import {
  CREATE_STRIPE_INTENT,
  CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD,
  CREATE_SUBSCRIPTION,
} from "../graphql/subscription";
import { useEffect, useState } from "react";
import { VerifyAccountResponse } from "../pages/verify-email";

const useSubscription = () => {
  const [userdata, setUserdata] = useState<VerifyAccountResponse | null>(null);

  useEffect(() => {
    const getUserdata = localStorage.getItem("userdata");

    if (getUserdata) {
      setUserdata(JSON.parse(getUserdata));
    }

    //eslint-disable-next-line
  }, []);

  const [createStripeSetupIntent] = useMutation(CREATE_STRIPE_INTENT, {
    context: {
      headers: {
        authorization: `Bearer ${userdata?.accessToken}`,
      },
    },
    errorPolicy: "all",
    onError() {},
  });
  const createSetupIntent = async () => {
    const res = await createStripeSetupIntent();
    return res;
  };

  const [convertStripeSetupIntentToPaymentMethod] = useMutation(
    CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD,
    {
      context: {
        headers: {
          authorization: `Bearer ${userdata?.accessToken}`,
        },
      },
      errorPolicy: "all",
      onError() {},
    }
  );
  const convertToPaymentMethod = async (setupIntentId: string) => {
    const res = await convertStripeSetupIntentToPaymentMethod({
      variables: {
        setupIntentId,
      },
    });
    return res;
  };

  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION, {
    context: {
      headers: {
        authorization: `Bearer ${userdata?.accessToken}`,
      },
    },
    errorPolicy: "all",
    onError() {},
  });
  const handleCreateSubscription = async (paymentMethodId: string, planId: string) => {
    const res = await createSubscription({
      variables: {
        paymentMethodId,
        planId,
      },
    });
    return res;
  };

  return { createSetupIntent, convertToPaymentMethod, handleCreateSubscription };
};

export default useSubscription;

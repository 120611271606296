import React from "react";
import { SpinnerCircularFixed } from "spinners-react";
import "./index.scss";

const SuspenseLoader = () => {
  return (
    <div className="dark-bg lighter disappear">
      <SpinnerCircularFixed
        size={50}
        thickness={100}
        speed={100}
        color="rgba(255, 255, 255, 1)"
        secondaryColor="rgba(0, 0, 0, 0.44)"
      />
    </div>
  );
};

export default SuspenseLoader;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Authheader } from "../auth/authheader";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import Payment from "./Payment";
import "./checkout.scss";

export default function StripeCheckout() {
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const location = useLocation();
  const [discountCode, setDiscountCode] = useState<{ code: string; off: string }>({
    code: "",
    off: "",
  });
  const [allowDiscountChange, setAllowDiscountChange] = useState<boolean>(true);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (parseddata?.plan === "month") {
      setSelectedPlan("month");
    } else {
      setSelectedPlan("year");
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (parseddata?.code) {
      const data = { code: parseddata?.code, off: parseddata?.off ?? "" };
      setDiscountCode(data);
      setAllowDiscountChange(false);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <div className="authpage">
      <Helmet>
        <title>Add Payment Method</title>
        <meta name="description" content="Set up payment to finish" />
        <meta property="og:title" content="Add Payment Method" />
        <meta property="og:description" content="Set up payment to finish" />
      </Helmet>

      <Authheader noClick />

      <div className="setup-payment">
        <Payment
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          discountCode={discountCode}
          allowDiscountChange={allowDiscountChange}
        />
      </div>
    </div>
  );
}

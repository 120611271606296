type autoCompleteType = "on" | "off";

interface ITextInput {
	label?: string;
	inputid?: string;
	message?: string;
	iserror?: boolean;
	onChange?: (e?: any) => void;
	checked?: boolean;
	type?: string;
	placeholder?: string;
	value?: string;
	name?: string;
	style?: any;
	disabled?: boolean;
	autocomplete?: autoCompleteType;
}

export const Textinput = ({
	autocomplete,
	label,
	inputid,
	message,
	iserror,
	...rest
}: ITextInput) => {
	return (
		<div className="input-body">
			<label className="input-label" htmlFor={inputid}>
				{label}
			</label>
			<input
				className={`input-usual
        ${iserror ? "input-danger" : "input-neutral"}`}
				id={inputid}
				autoComplete={autocomplete || "off"}
				{...rest}
			/>
			{iserror ? <small className="input-small">{message}</small> : null}
		</div>
	);
};

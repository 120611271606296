import React from "react";
import BusinessWire from "../../assets/svg/media-coverage/businesswire.svg";
import TechCabal from "../../assets/svg/media-coverage/techcabal.svg";
import VA from "../../assets/svg/media-coverage/ventures.png";
import YahooFinance from "../../assets/svg/media-coverage/yahoofinance.svg";
import Benzinga from "../../assets/svg/media-coverage/benzinga.svg";

import "./media.scss";

const MediaFeature = () => {
  return (
    <div className="media-coverage" id="media-features">
      <div className="mc-inner-wrapper">
        <div className="mc-title">As featured in</div>

        <div className="mc-main">
          <a
            href="https://venturesafrica.com/yomi-ojos-cova-is-influencing-the-modern-culture-of-investing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width={180} src={VA} alt="VenturesAfrican" />
          </a>

          <a
            href="https://www.businesswire.com/news/home/20220207005142/en/Cova-Launches-Digital-Asset-Tracker-To-Help-Users-Track-Their-Assets-And-Avoid-Losses-In-The-Event-Of-Eventualities"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={BusinessWire} alt="BusinessWire" />
          </a>

          <a
            href="https://finance.yahoo.com/news/cova-launches-digital-asset-tracker-140000041.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={YahooFinance} alt="YahooFinance" />
          </a>

          <a
            href="https://techcabal.com/2022/03/02/meet-cova-a-potential-solution-to-the-worlds-unclaimed-assets-issues/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TechCabal} alt="TechCabal" />
          </a>

          <a
            href="https://www.benzinga.com/money/cova-review"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Benzinga} alt="Benzinga" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MediaFeature;

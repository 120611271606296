type buttonType = "primary" | "secondary" | "delete";
type sizeType = "small" | "big";

interface IButton {
	size: sizeType;
	type: buttonType;
	click?: () => void;
	children: React.ReactNode;
	disabled?: boolean;
	id?: string;
	extraClasses?: string;
}

const MainButton = ({
	type,
	children,
	click,
	disabled,
	id,
	extraClasses,
	size,
}: IButton) => {
	return (
		<button
			id={id}
			disabled={disabled}
			onClick={click}
			className={`btn-most
        ${extraClasses}
        ${size === "big" ? `btn-big` : size === "small" ? `btn-small` : "btn-small"}
        ${disabled ? `btn-not-allowed` : `btn-clickable`}
        ${
					type === "primary"
						? `btn-prim`
						: type === "secondary"
						? `btn-sec`
						: type === "delete"
						? `btn-del`
						: "btn-other"
				}`}
		>
			{children}
		</button>
	);
};

export default MainButton;

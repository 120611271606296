import { useState, createContext } from "react";

const LoaderContext = createContext<any>([]);

function LoaderProvider(props: any) {
	const [showLoader, setShowLoader] = useState(false);

	return (
		<LoaderContext.Provider value={[showLoader, setShowLoader]}>
			{props.children}
		</LoaderContext.Provider>
	);
}

export { LoaderContext, LoaderProvider };

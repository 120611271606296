export const Errornotf = ({ message, type, ...rest }: any) => {
  return (
    <>
      {message ? (
        <div {...rest} className={`notification ${type || "danger-not"}`}>
          <p className="err-message">{message}</p>
        </div>
      ) : null}
    </>
  );
};

import React from "react";
import { ReactComponent as Star } from "../../assets/svg/redesign/star.svg";

import "./reviewcard.scss";

const Reviewcard = ({ desc, name, review, image }: any) => {
  return (
    <div className="review-card">
      <div className="user-info">
        {/* <div className="u-i-left">
          <img src={image} alt="review-user" />
        </div> */}

        <div className="u-i-right">
          <div>
            <Star /> <Star /> <Star /> <Star /> <Star /> <span>{desc}</span>
          </div>
          <div>{name}</div>
        </div>
      </div>

      <div className="r-c-text">{review}</div>
    </div>
  );
};

export default Reviewcard;

import { useMemo, useState, Fragment, useContext, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe, SetupIntentResult } from "@stripe/stripe-js";
import { Textinput } from "../../components/inputs/textinput";
import { ReactComponent as StripeLogo } from "../../assets/svg/stripelogo.svg";
import { ReactComponent as CheckIcon } from "../../assets/svg/check-halfcircle.svg";
import { LoaderContext } from "../../contexts/loader";
import useSubscription from "../../hooks/useSubscription";

import { toast } from "react-toastify";
import "./checkout.scss";
import { VerifyAccountResponse } from "../verify-email";
import { FetchResult, useLazyQuery } from "@apollo/client";
import { USER_CONFIG } from "../../graphql/auth";
import { Plan } from "../choseplan";

interface Props {
  selectedPlan: string;
  setSelectedPlan: (value: string) => void;
  discountCode: { code: string; off: string };
  allowDiscountChange: boolean;
}

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#1E2329",
          letterSpacing: "0.08em",
          "::placeholder": {
            color: "#b7b7b7",
          },
        },
        invalid: {
          color: "#EE425A",
        },
      },
    }),
    []
  );

  return options;
};

const Payment = ({ selectedPlan, setSelectedPlan, discountCode, allowDiscountChange }: Props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

  return (
    <Fragment>
      <Elements stripe={stripePromise}>
        <StripePortal
          {...{
            selectedPlan,
            setSelectedPlan,
            discountCode,
            allowDiscountChange,
          }}
        />
      </Elements>
    </Fragment>
  );
};

const StripePortal = ({
  selectedPlan,
  setSelectedPlan,
  discountCode,
  allowDiscountChange,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [, setShowLoader] = useContext(LoaderContext);
  const [discountInput, setDiscountInput] = useState<string>("");
  const [userdata, setUserdata] = useState<VerifyAccountResponse | null>(null);
  const { createSetupIntent, convertToPaymentMethod, handleCreateSubscription } = useSubscription();
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [plan, setPlan] = useState<Plan | null>(null);

  useEffect(() => {
    const selected = plans.find((p) => p.interval === selectedPlan);

    if (selected) {
      setPlan(selected);
    }
  }, [plan, plans, selectedPlan]);

  useEffect(() => {
    if (userdata) {
      getUserConfig({ variables: { partner: "STRIPE" } });
    }
    //eslint-disable-next-line
  }, [userdata]);

  const [getUserConfig, { data: userConfigResponse }] = useLazyQuery(USER_CONFIG, {
    context: {
      headers: {
        authorization: `Bearer ${userdata?.accessToken}`,
      },
    },
    errorPolicy: "all",
    onCompleted: () => {
      if (userConfigResponse?.config?.plans) {
        setPlans(userConfigResponse.config.plans);
      }
    },
    onError() {},
  });

  useEffect(() => {
    const getUserdata = localStorage.getItem("userdata");

    if (getUserdata) {
      setUserdata(JSON.parse(getUserdata));
    }

    //eslint-disable-next-line
  }, []);

  const goTo = (href: string) => {
    window.location.href = href;
  };

  const redirect = () => {
    if (userdata?.accessToken) {
      goTo(`${process.env.REACT_APP_SIGN_LINK}signup?token=${userdata.accessToken}`);
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userdata?.accessToken) {
      handleGetSetupIntent();
    }
    //eslint-disable-next-line
  }, [userdata]);

  const handleGetSetupIntent = () => {
    createSetupIntent().then((res: FetchResult<any, Record<string, any>, Record<string, any>>) => {
      if (!res.errors) {
        const response: SetupIntent = res.data.createStripeSetupIntent;
        setSetupIntent(response);
      } else {
        toast.error("Something went wrong, please try again");
      }
    });
  };

  const submit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);

    if (card && setupIntent) {
      setShowLoader(true);
      stripe
        .confirmCardSetup(setupIntent?.client_secret, {
          payment_method: {
            card: card,
          },
        })
        .then((result: SetupIntentResult) => {
          if (result.error) {
            setShowLoader(false);
            toast.error(result.error.message ?? "Failed to Subscribe");
          } else {
            if (
              result.setupIntent.status === "processing" ||
              result.setupIntent.status === "succeeded"
            ) {
              convertToPaymentMethod(setupIntent.id).then(
                (res: FetchResult<any, Record<string, any>, Record<string, any>>) => {
                  if (!res.errors) {
                    const response: PaymentMethod =
                      res.data.convertStripeSetupIntentToPaymentMethod;

                    handleCreateSubscription(response.id, plan?.id ?? "").then(
                      (res: FetchResult<any, Record<string, any>, Record<string, any>>) => {
                        setShowLoader(false);

                        if (!res.errors) {
                          const response: { createSubscription: Subscription } = res.data;

                          if (response.createSubscription) {
                            toast.success("Card details saved successfully");
                            setShowLoader(false);

                            redirect();
                          }
                        } else {
                          toast.error("Unable to create subscription");
                        }
                      }
                    );
                  } else {
                    toast.error("Unable to create subscription");
                    setShowLoader(false);
                  }
                }
              );
            } else {
              toast.error("Unable to create subscription");
            }
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.error("Failed to save card");
        });
    }
  };

  // const getDiscountPrice = (value: number) => {
  //   if (discountCode?.off) {
  //     return value - (parseInt(discountCode?.off) / 100) * value;
  //   }
  // };

  useEffect(() => {
    if (discountCode) {
      setDiscountInput(discountCode?.code);
    }
  }, [discountCode]);

  return (
    <div className="payment-modal">
      <div className="p-title">Set up payment to finish</div>
      <div className="p-desc">
        Your card will not be charged until after your 14 days free period. You can cancel your
        subscription at any time.
      </div>
      <div className="p-change-card">
        <div className="c-c-left">
          <div className="l-top">
            {selectedPlan === "month" ? "$10/month" : "$100/year (save $20)"}
          </div>

          <div className="l-bottom">Chosen Plan</div>
        </div>

        <div
          className="c-c-right click no-select"
          onClick={() => {
            if (selectedPlan === "month") {
              setSelectedPlan("year");
            } else {
              setSelectedPlan("month");
            }
          }}
        >
          Change
        </div>
      </div>

      <div className={`discount-code ${discountCode?.off ? "add-space" : ""}`}>
        <div className="d-c-input">
          {!allowDiscountChange && (
            <div className="remove-discount-title">
              <span>Discount successfully applied</span> <CheckIcon />
            </div>
          )}

          <Textinput
            onChange={(e: any) => {
              setDiscountInput(e.target.value);
            }}
            value={discountInput}
            label={allowDiscountChange ? "Discount Code (Optional)" : ""}
            inputid="discount"
            type="text"
            placeholder=""
            disabled={!allowDiscountChange}
            message={"Incorrect Code."}
          />

          {discountCode?.off && (
            <div className="discount-slash">
              You will be charged {plan && plan?.amount / 100} for the first {selectedPlan}
            </div>
          )}
        </div>
      </div>

      <form
        onSubmit={(e) => {
          submit(e);
        }}
        className="stripe-form"
      >
        <label className="form-label">
          Card number
          <CardNumberElement options={options} className="form-item" />
        </label>

        <div className="form-row">
          <label className="form-label">
            Expiry
            <CardExpiryElement options={options} className="form-item" />
          </label>

          <label className="form-label">
            CVV
            <CardCvcElement options={options} className="form-item" />
          </label>
        </div>

        <div className="stripe-form-btn">
          <button className="s-f-btn click">Add card</button>
        </div>
      </form>
      <div className="powered">We don’t save your card in our database.</div>
      <div className="powered" style={{ marginTop: "6px" }}>
        All your transactions are powered by{" "}
        <span>
          <a href="https://stripe.com/docs/security" target="_blank" rel="noreferrer">
            <StripeLogo />
          </a>
        </span>
      </div>
    </div>
  );
};

export type SetupIntent = {
  client_secret: string;
  id: string;
  payment_method_options: {
    card: Card;
  };
  payment_method_types: string[];
  status: string;
  usage: string;
};

interface Card {
  mandate_options: any;
  network: any;
  request_three_d_secure: string;
}

export type PaymentMethod = {
  card_brand: string;
  card_country: string;
  card_exp_month: number;
  card_exp_year: number;
  card_last4: string;
  created_at: string;
  id: string;
  partner: string;
  type: string;
};

export type Subscription = {
  cancel_at: Date | null;
  created_at: Date;
  current_period_end: Date;
  current_period_start: Date;
  customer_id: string;
  ended_at: Date;
  id: string;
  partner: string;
  partner_ref: string;
  partner_status: string;
  payment_method: PaymentMethod | null;
  plan: Plan;
  quantity: number;
  started_at: string;
};

export default Payment;

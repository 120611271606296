import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.svg";
import MainButton from "../buttons/mainButton";
import "./coupon-details.scss";

const CouponDetails = ({ closeModal }: any) => {
  const copyCode = () => {
    navigator.clipboard.writeText("CRYPTOFEST20");
    toast.success("Code copied to clipboard");
  };

  return (
    <div className="coupon-details">
      <div className="c-d-header">
        <CloseIcon className="click" onClick={closeModal} />
      </div>

      <div className="c-d-main">
        <div className="c-d-m-row-one">Ready to get started?</div>

        <div className="c-d-m-row-two">
          Get 20% OFF the premium plan by applying discount code below.
        </div>

        <div className="c-d-m-row-three">CRYPTOFEST20</div>

        <MainButton click={copyCode} type="primary" size="small" extraClasses="move-right b-btn">
          <span className="text-p1">Copy Code</span>
        </MainButton>
      </div>
    </div>
  );
};

export default CouponDetails;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CookieIcon } from "../../assets/svg/cookie-icon.svg";
import "./cookie.scss";

export const CookiePolicy = () => {
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("acceptCookies") !== "yes") {
        setShowCookiePolicy(true);
      }
    }, 3000);
  }, []);

  return (
    <div className="cookie-wrapper">
      {showCookiePolicy ? (
        <div className="cookie-policy-container">
          <div className="content-c">
            <CookieIcon className="cookie-icon" /> By using this website, you agree to our
            <span
              className="cookies-link"
              onClick={() => {
                navigate("/cookie-policy");
              }}
            >
              use of cookies.
            </span>
            We use cookies to give you a great browsing experience.
            <button
              className="accept-button"
              onClick={() => {
                localStorage.setItem("acceptCookies", "yes");
                setShowCookiePolicy(false);
              }}
            >
              Accept
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

import { useEffect, useState, Suspense } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import Modal from "react-modal";
import AOS from "aos";
import LandingPage from "../../pages/landingpage";
import { CookiePolicy } from "../../components/cookiepolicy";
import CouponDetails from "./../couponDetails/couponDetails";
import SignUp from "../../pages/auth/signup";
import SuspenseLoader from "../suspenseloader";
import "aos/dist/aos.css";
import PrivacyPage from "../../pages/auth/privacypage";
import TermsandConditionPage from "../../pages/auth/termsandcondition";
import CookiePolicyPage from "../../pages/cookiepolicy";
import Security from "../../pages/auth/security";
import Success from "../../pages/success";
// import Company from "../../pages/company";
import Pricing from "../../pages/pricing";
import Howitworks from "../../pages/howItWorks";
import Whycova from "../../pages/whycova";
import Verifyemailpage from "../../pages/verify-email";
import ADSLandingPage from "../../pages/adsLandingPage";
import ADSLandingPage2 from "../../pages/adsLandingPage2";
import ADSLandingPage3 from "../../pages/adsLandingPage3";
// import ADSLandingPage4 from "../../pages/adsLandingPage4";
// import ADSLandingPage5 from "../../pages/adsLandingPage5";
// import ADSLandingPage6 from "../../pages/adsLandingPage6";
import Business from "../../pages/business/business";
import ChosePlan from "../../pages/choseplan";
import StripeCheckout from "../../pages/stripecheckout";
import EulaPage from "../../pages/auth/eula";
import { ApolloClient, InMemoryCache, ApolloProvider, DefaultOptions } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

export const MyRouter = () => {
  const location = useLocation();
  const [cookiePolicy, setCookiePolicy] = useState<boolean>(false);
  const [showCouponModal, setShowCouponModal] = useState<boolean>(false);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  const client = new ApolloClient({
    //@ts-ignore
    link: createUploadLink({
      uri: "https://api-v2.getcova.com/graphql",
      headers: {},
    }),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: defaultOptions,
    name: "web",
    version: "",
  });

  useEffect(() => {
    AOS.init();

    setTimeout(() => {
      setCookiePolicy(true);
    }, 25000);

    let parseddata: any = queryString.parse(location.search);

    if (parseddata?.ir_co) {
      localStorage.setItem("ir_code", parseddata?.ir_co);
    }

    if (parseddata?.gsc) {
      setTimeout(() => {
        setShowCouponModal(true);
      }, 10000);
    }

    // eslint-disable-next-line
  }, []);

  const modalStyles = {
    content: {
      top: "30%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0px",
      overflow: "hidden",
      transform: "translate(-50%, -30%)",
      zIndex: "305 !important",
      backgroundColor: "white",
    },
  };

  return (
    <>
      <ApolloProvider client={client}>
        <div className={showCouponModal ? "modal-overlay-active" : ""}>
          <Modal
            ariaHideApp={false}
            isOpen={showCouponModal}
            onRequestClose={() => {}}
            style={modalStyles}
            contentLabel="Example Modal"
          >
            <CouponDetails
              closeModal={() => {
                setShowCouponModal(false);
              }}
            />
          </Modal>

          <Suspense fallback={<SuspenseLoader />}>
            <Routes>
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/eula" element={<EulaPage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/more" element={<ADSLandingPage2 />} />
              <Route path="/terms-and-condition" element={<TermsandConditionPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/security" element={<Security />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/success" element={<Success />} />
              <Route path="/verify-email" element={<Verifyemailpage />} />
              {/* <Route path="/company" element={<Company />} /> */}
              <Route path="/how-it-works" element={<Howitworks />} />
              <Route path="/why-cova" element={<Whycova />} />
              <Route path="/business" element={<Business />} />
              <Route path="/global" element={<ADSLandingPage />} />
              <Route path="/chose-plan" element={<ChosePlan />} />
              <Route path="/card-setup" element={<StripeCheckout />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/join" element={<ADSLandingPage3 />} />
              {/* <Route path="/join2" element={<ADSLandingPage4 />} />
          <Route path="/join3" element={<ADSLandingPage5 />} />
        <Route path="/join4" element={<ADSLandingPage6 />} /> */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>

          {cookiePolicy && <CookiePolicy />}
        </div>
      </ApolloProvider>
    </>
  );
};

import React, { useState } from "react";
import { Hideeyeicon } from "../icons/hideeye";
import { Showeyeicon } from "../icons/showeye";

interface ITextInput {
	label?: string;
	inputid?: string;
	message?: string;
	iserror?: boolean;
	onChange?: (e?: any) => void;
	checked?: boolean;
	type?: string;
	placeholder?: string;
	value?: string;
	name?: string;
	style?: any;
	disabled?: boolean;
	onclickicon: any;
}

export const TextinputwithLeftIcon = ({
	label,
	inputid,
	message,
	onclickicon,
	iserror,
	...rest
}: ITextInput) => {
	const [isHideEye, setIsHideEye] = useState(true);
	const clickicon = () => {
		setIsHideEye(!isHideEye);
	};
	return (
		<div className="input-body">
			<label className="input-label" htmlFor={inputid}>
				{label}
			</label>
			<div
				className={`input-unusual input-check
                ${iserror ? "input-danger" : "input-neutral"}`}
			>
				<input className="input-eye" id={inputid} {...rest} />
				<div onClick={clickicon}>
					{isHideEye ? (
						<span className="pointer" onClick={onclickicon}>
							<Hideeyeicon />
						</span>
					) : (
						<span className="pointer" onClick={onclickicon}>
							<Showeyeicon />
						</span>
					)}
				</div>
			</div>
			{iserror ? <small className="input-small">{message}</small> : null}
		</div>
	);
};

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { MyRouter } from "./components/router";
import { BrowserRouter as Router } from "react-router-dom";
import { Pageloader } from "./components/utilities/pageloader";
import { LoaderProvider } from "./contexts/loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  draggable: false,
  autoClose: 2000,
});

const rootElement = document.getElementById("root");

if (rootElement!.hasChildNodes()) {
  ReactDOM.hydrate(
    <Router>
      <LoaderProvider>
        <ToastContainer />
        <MyRouter />
        <Pageloader />
      </LoaderProvider>
    </Router>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Router>
      <LoaderProvider>
        <ToastContainer />
        <MyRouter />
        <Pageloader />
      </LoaderProvider>
    </Router>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";

export const HowitworksIcon = () => {
  return (
    <svg
      width="90"
      height="117"
      viewBox="0 0 90 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.3334 5.88212C62.7684 15.031 61.6965 46.4805 49.3579 58.1702C37.0193 69.8599 18.6117 69.3287 21.0844 49.2643C23.557 29.2 40.6395 18.9175 53.536 47.9945C66.4324 77.0715 49.9885 80.0492 45.2352 112.69"
        stroke="#0056CD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2041 106.711L44.4757 114.54L52.3049 111.268"
        stroke="#0056CD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { SpinnerCircularFixed } from "spinners-react";
import "./index.scss";
import { useContext } from "react";
import { LoaderContext } from "../../contexts/loader";

export const Pageloader = () => {
	const [showLoader] = useContext(LoaderContext);

	return (
		<>
			<div className={`dark-bg ${showLoader && "disappear"}`}>
				<SpinnerCircularFixed
					size={50}
					thickness={100}
					speed={100}
					color="rgba(255, 255, 255, 1)"
					secondaryColor="rgba(0, 0, 0, 0.44)"
				/>
			</div>
		</>
	);
};

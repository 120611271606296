import "./auth.scss";
import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";

export const Authheader = ({ noClick }: any) => {
  return (
    <div className="authheader">
      <div className="logocontainer">
        {noClick ? (
          <div className="ttt">
            <Logo />
            {process.env.REACT_APP_DEPLOY_ENV === "beta" && <p className="beta-flag">Beta</p>}
          </div>
        ) : (
          <a href={process.env.REACT_APP_HOME} className="no-underline">
            <div className="ttt">
              <Logo />
              {process.env.REACT_APP_DEPLOY_ENV === "beta" && <p className="beta-flag">Beta</p>}
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

import React from "react";

export const MarksuccessIcon = () => {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4999 0.75L4.62488 7.625L1.49988 4.5"
        stroke="#1E2329"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { gql } from "@apollo/client";

export const CREATE_STRIPE_INTENT = gql`
  mutation CreateStripeSetupIntent {
    createStripeSetupIntent {
      client_secret
      id
      payment_method_options
      payment_method_types
      status
      usage
    }
  }
`;

export const CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD = gql`
  mutation ConvertStripeSetupIntentToPaymentMethod($setupIntentId: String!) {
    convertStripeSetupIntentToPaymentMethod(setupIntentId: $setupIntentId) {
      card_brand
      card_country
      card_exp_month
      card_exp_year
      card_last4
      created_at
      id
      partner
      partner_ref
      type
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($paymentMethodId: String!, $planId: String!) {
    createSubscription(paymentMethodId: $paymentMethodId, planId: $planId) {
      cancel_at
      created_at
      current_period_end
      current_period_start
      customer_id
      ended_at
      id
      partner
      partner_ref
      partner_status
      payment_method {
        card_brand
        card_country
        card_exp_month
        card_exp_year
        card_last4
        created_at
        id
        partner
      }
      payment_method_id
      plan {
        active
        amount
        code
        currency
        id
        interval
        partner
        partner_ref
      }
      plan_id
      quantity
      started_at
      status
    }
  }
`;

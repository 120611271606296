import axios from "axios";
import { toast } from "react-toastify";
import { Textinput } from "../inputs/textinput";
import { useForm, Controller } from "react-hook-form";
import { useContext } from "react";
import { LoaderContext } from "../../contexts/loader";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.svg";
import MainButton from "../buttons/mainButton";
import "./collect-business-details.scss";

const CollectBusinessDetails = ({ closeModal }: any) => {
  const [, setShowLoader] = useContext(LoaderContext);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const onSubmit = (values: any) => {
    setShowLoader(true);

    axios
      .post(
        `${process.env.REACT_APP_API}api/reseller/details`,
        { ...values, source: "BUSINESS" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        toast.success("Interest form submitted");
        setShowLoader(false);
        closeModal();
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.response?.data?.message ?? "Something went wrong");
      });
  };

  return (
    <div className="business-details">
      <div className="b-d-header">
        <div className="h-title">Interest Form</div>

        <CloseIcon className="click" onClick={closeModal} />
      </div>

      <form className="b-d-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-item">
          <Controller
            name="firstName"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Full Name"
                inputid="firstName"
                type="text"
                placeholder="John Doe"
                iserror={errors.firstName}
                message={"This field is required"}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name="businessName"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Business Name"
                inputid="businessName"
                type="text"
                placeholder="Maverick Insurance Co."
                iserror={errors.businessName}
                message={"This field is required"}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name="email"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Business Email"
                inputid="email"
                type="email"
                placeholder={"contact@maverickinsurance.co"}
                iserror={errors.email}
                message={"Please provide a correct email address."}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name="address"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Address"
                inputid="address"
                type="text"
                placeholder="123 Main Street, New York, NY 10030"
                iserror={errors.address}
                message={"This field is required"}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name="link"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Website URL"
                inputid="link"
                type="text"
                placeholder="https://maverickin.co"
                iserror={errors.link}
                message={"This field is required"}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name="message"
            defaultValue={null}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textinput
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                checked={value}
                label="Additional Info"
                inputid="message"
                type="text"
                placeholder="Input additional information"
                iserror={errors.message}
                message={"This field is required"}
              />
            )}
          />
        </div>

        <MainButton type="primary" size="big" extraClasses="move-right b-btn">
          <span className="text-p1">Submit Details</span>
        </MainButton>
      </form>
    </div>
  );
};

export default CollectBusinessDetails;
